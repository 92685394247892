import TableBasic from "components/table/TableBasic";
import TableCellText from "components/table/TableTextCell";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useToast } from 'rc-toastr';

import { useBetween } from "use-between";
import _loading from "_loading";


const Models = () => {
    const [data, setData] = useState([]);
    const { toast } = useToast();

    const { setLoading } = useBetween(_loading);
    // const downloadFile = () => {
    //     const { modelsFile } = this.state;
    //     const fileName = "modelos";
    //     const json = JSON.stringify(modelsFile, null, 2);
    //     const blob = new Blob([json], { type: "application/json" });
    //     const href = URL.createObjectURL(blob);

    //     // create "a" HTLM element with href to file
    //     const link = document.createElement("a");
    //     link.href = href;
    //     link.download = fileName + ".json";
    //     document.body.appendChild(link);
    //     link.click();

    //     // clean up "a" element & remove ObjectURL
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    // }

    const columns = [
        {
            Header: "ID",
            accessor: "uid",
        },
        {
            Header: "Nombre",
            accessor: "nombre",
        },
        {
            Header: "Entradas",
            accessor: "cant_entradas",
        },
        {
            Header: "Dispositivos",
            accessor: "cant_dispositivos",
        },
    ];

    const importData = async (file) => {
        // cargar un archivo local en una variable?
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.json';
        fileInput.onchange = async (e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                const data = JSON.parse(text);
                const response = await axios.post(`/web/models/upload`, { data });
                if (response.data.success) {
                    loadData();
                }
            }
            reader.readAsText(file);
        }
        fileInput.click();

    }

    const downloadData = async () => {
        const response = await axios.get(`/web/models/download`);
        // descargar la respuesta en un archivo?
        if (response.data.success) {
            const fileName = "modelos";
            const json = JSON.stringify(response.data.data);
            const blob = new Blob([response.data.data], { type: "application/json" });
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }

    }

    const loadData = async () => {
        const response = await axios.get(`/web/models/`);
        if (response.data.success) {
            const data = response.data.data.map((item) => ({
                uid: <TableCellText value={item.uid} />,
                nombre: <TableCellText value={item.nombre} />,
                cant_entradas: <TableCellText value={item.cant_entradas} />,
                cant_dispositivos: <TableCellText value={item.cant_dispositivos} />,
                key: item._id
            }));
            setData(data);
        }
        console.log('Loading data...');
    };

    useEffect(() => {
        loadData();
    }, [setLoading]);

    const deleteData = async (_id) => {
        setLoading(true);
        const response = await axios.delete(`/web/models/${_id}`);
        setLoading(false);
        if (response.data.success) {
            loadData();
        } else {
            toast.error(response.data.msj);
        }
    }

    return (
        <>
            <div className="mt-3 dark:text-white">
                <TableBasic columnsData={columns} tableData={data} titulo="Modelos"
                    downloadButton={() => { downloadData() }}
                    uploadButton={() => { importData() }
                    }
                />
            </div>
        </>
    );
};

export default Models;