import React from "react";

import MainDashboard from "views/client/default";

import { MdHome } from "react-icons/md";
import Devices from "views/client/devices";
import { layout } from "@chakra-ui/react";
import Groups from "views/client/groups";
import { SlOrganization } from "react-icons/sl";
import { MdComputer } from "react-icons/md";

const routes = [
  {
    name: "Home",
    layout: "/client",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Organizaciones",
    layout: "/client",
    path: "groups",
    icon: <SlOrganization className="h-6 w-6" />,
    component: <Groups />,
  },
  {
    name: "Dispositivos",
    layout: "/client",
    path: "devices",
    icon: <MdComputer className="h-6 w-6" />,
    component: <Devices />,
  },
];
export default routes;
