import Card from "../card";
import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";

const TableBasic = (props) => {
  const {
    columnsData,
    tableData,
    title,
    addButton,
    downloadButton,
    uploadButton,
    header,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const initialState = React.useMemo(() => props.initialState);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  //initialState.pageSize = 5;
  //initialState.pageIndex = 0;

  const pagClass =
    "p-[10px] x-4 text-sm font-medium text-gray-900 bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";

  const hasButtons = uploadButton || downloadButton || addButton;
  
  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="text-xl font-bold text-navy-700 dark:text-white">
        {title || ""}
      </div>
      {header && header}
      {hasButtons && (
        <header className="flex flex-row-reverse">
          {downloadButton && typeof downloadButton === "function" && (
            <div className="text-left">
              <button
                onClick={downloadButton}
                className="border-1 mr-[10px] rounded-xl border-gray-300 bg-green-600 px-2 py-2 text-base font-medium text-navy-700 text-white transition duration-200 hover:bg-green-200 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
              >
                Descargar archivo JSON
              </button>
            </div>
          )}
          {uploadButton && typeof uploadButton === "function" && (
            <div className="text-left">
              <button
                onClick={uploadButton}
                className="border-1 mr-[10px] rounded-xl border-gray-300 bg-blue-600 px-2 py-2 text-base font-medium text-navy-700 text-white transition duration-200 hover:bg-green-200 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
              >
                Cargar archivo JSON
              </button>
            </div>
          )}
          {addButton && typeof addButton === "function" && (
            <button
              onClick={addButton}
              className="border-1 mr-[10px] rounded-xl border-gray-300 bg-green-600 px-2 py-2 text-base font-medium text-navy-700 text-white transition duration-200 hover:bg-green-200 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              + Nuevo
            </button>
          )}
        </header>
      )}

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pb-[10px] pr-14 text-start dark:!border-navy-700"
                  >
                    <div className="flex w-full justify-between pr-10 text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="hover:bg-indigo-50 dark:hover:bg-indigo-900"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className="px-[10px] pb-[20px] pt-[14px] sm:text-[14px]"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="relative mt-7 flex items-center justify-between">
          <span>
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <div className="inline-flex rounded-md shadow-sm" role="group">
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className={pagClass + " rounded-s-lg border"}
            >
              {"<<"}
            </button>{" "}
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={pagClass + " border-b border-t"}
            >
              {"<"}
            </button>{" "}
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={pagClass + " border-b border-t"}
            >
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className={pagClass + " rounded-e-lg border"}
            >
              {">>"}
            </button>
          </div>
          <select
            value={pageSize}
            className="mb-6 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} por página
              </option>
            ))}
          </select>
        </div>
      </div>
    </Card>
  );
};

export default TableBasic;
