import React, { useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ClientLayout from "layouts/client";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import PrivateRoute from "layouts/PrivateRoute";
import Loading from "react-fullscreen-loading";
import { useBetween } from "use-between";
import _loading from "_loading";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChakraProvider } from "@chakra-ui/react";

const App = () => {
  const { loading } = useBetween(_loading);
  const ref = useRef(document.body);

  useEffect(() => {
    ref.current.style.touchAction = "none";
  }, []);

  return (
    <>
      <ChakraProvider>
        <GoogleOAuthProvider clientId="81109027111-73ma3c0pp14poiojmk6cdltpkebfpg93.apps.googleusercontent.com">
          {loading ? (
            <Loading loading background="#f1f1f1" loaderColor="#3498db" />
          ) : null}
          <Routes>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route exact path="/admin" element={<PrivateRoute type="admin" />}>
              <Route exact path="/admin/*" element={<AdminLayout />} />
              <Route
                path="/admin"
                element={<Navigate to="/admin/default" replace />}
              />
            </Route>
            <Route
              exact
              path="/client"
              element={<PrivateRoute type="client" />}
            >
              <Route exact path="/client/*" element={<ClientLayout />} />
              <Route
                path="/client"
                element={<Navigate to="/client/default" replace />}
              />
            </Route>
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </GoogleOAuthProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
